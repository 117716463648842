<template>
  <v-dialog
    v-model="createMatchModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar Partida
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mx-5 mt-5"
          @submit.prevent="createMatch()"
        >
         <v-autocomplete
            item-text="name"
            item-value="_id"
            label="Time A *"
            hint="Digite o nome do time"
            no-data-text="Sem dados para listagem."
            class="mb-2"
            v-model="match.teamA"
            :items="teams"
            :loading="loadingTeams"
            hide-no-data
            outlined
            persistent-hint
            :rules="[rules.required]"
          />

          <v-autocomplete
            item-text="name"
            item-value="_id"
            label="Time B *"
            hint="Digite o nome do time"
            no-data-text="Sem dados para listagem."
            class="mb-2"
            v-model="match.teamB"
            :items="teams"
            :loading="loadingTeams"
            hide-no-data
            outlined
            persistent-hint
            :rules="[rules.required, rules.differentValues]"
          />

          <v-datetime-picker
            v-model="match.date"
            :datePickerProps="datePickerProps"
            :textFieldProps="textFieldProps"
            :timePickerProps="timeOpts"
            clearText="limpar"
            label="Data da partida *"
            date-format="dd/MM/yyyy"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>

          <v-text-field
            v-model="match.goalsA"
            outlined
            required
            persistent-hint
            class="mb-2"
            type="number"
            label="Gols feitos pelo Time A"
          />

          <v-text-field
            v-model="match.goalsB"
            outlined
            required
            persistent-hint
            class="mb-2"
            type="number"
            label="Gols feitos pelo Time B"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createMatch()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      saving: false,
      loadedTeams: false,
      loadingTeams: false,
      valid: true,
      match: {
        teamA: null,
        teamB: null,
        goalsA: 0,
        goalsB: 0,
        date: null
      },
      teams: [],
      datePickerProps: {
        'no-title': true,
        scrollable: true,
        locale: 'pt-br'
      },
      textFieldProps: {
        readonly: true,
        outlined: true
      },
      timeOpts: {
        format: '24hr'
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório.',
        differentValues: value => value !== this.match.teamA || 'Você não pode selecionar o mesmo time.'
      }
    }
  },
  computed: {
    createMatchModal: {
      get () {
        return this.$store.state.createMatchModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createMatchModal',
          value: val
        })
      }
    }
  },
  watch: {
    createMatchModal (val) {
      if (!val) {
        this.match = {
          teamA: null,
          teamB: null,
          goalsA: 0,
          goalsB: 0
        }
      } else if (!this.loadedTeams) {
        this.getTeams()
      }
    }
  },
  methods: {
    createMatch () {
      if (this.$refs.form.validate()) {
        if (!this.match.date) {
          this.$toast.warning('Informe a data e hora de início da partida.')
          return
        }

        this.saving = true
        const championshipId = this.$store.state.selectedChampionship
        const roundId = this.$store.state.selectedRound

        this.$http.post(`/championships/${championshipId}/rounds/${roundId}/matches`, this.match)
          .then((res) => {
            this.saving = false
            this.createMatchModal = false
            this.$emit('created')
            this.$toast.success('Cadastro realizado com sucesso.')
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    getTeams () {
      this.loadingTeams = true
      const championshipId = this.$store.state.selectedChampionship

      this.$http.get(`/championships/${championshipId}/teams`)
        .then((res) => {
          this.teams = res.data.data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => {
          this.loadedTeams = true
          this.loadingTeams = false
        })
    }
  }
}
</script>
